import React from 'react';
import './App.css';
import ParticleComponent from "./ParticleComponent";
import Content from "./Content";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container} from "react-bootstrap";

function App() {
	return (
		<Container fluid>
			<ParticleComponent/>
			{
				<Content/>
			}
		</Container>
	);
}

export default App;
