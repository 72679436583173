import React from 'react';
import './sections.css';
import "../index.css"
import TextLoop from "react-text-loop";
import NextButton from "../buttons/NextButton";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";


class Intro extends React.Component {

	constructor(props) {
		super(props);
	}

	onClickNext() {
		this.props.fullpageApi.moveSectionDown()
		console.log(this.props.fullpageApi.getActiveSection())
	}

	render() {
		return (
			<Container fluid>
				<Container className="Section-Body">
					<Row className="Section-Row-Centered Welcome-Text">
						<h>Hello! I'm <h className="Name-Text code">Yashodhan Kumthekar</h></h>
					</Row>
					<Row className="Section-Row-Centered">
						<h className="Intro-Text">I'm a passionate <TextLoop
							mask
							interval={900}>
							<text className="Skills-Text-Style code"> Software Engineer</text>
							<text className="Skills-Text-Style code"> Full Stack Dev</text>
							<text className="Skills-Text-Style code"> Backend Engineer</text>
							<text className="Skills-Text-Style code"> Python Developer</text>
							<text className="Skills-Text-Style code"> Cloud Architect</text>
							<text className="Skills-Text-Style code"> Data Enthusiast</text>
						</TextLoop>
						</h>
					</Row>
				</Container>
				<Row className="Footer-Container">
					<NextButton onClick={() => this.onClickNext()}/>
				</Row>
			</Container>
		);
	}

}

export default Intro;
