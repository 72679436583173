import React from "react";
import {FaAngleDoubleDown} from 'react-icons/fa';
import ".//buttons.css"

export default class NextButton extends React.Component {

	onClick() {
		this.props.onClick();
	}

	render() {
		return (
			<button
				className="btn"
				onClick={() => this.onClick()}
				style={{
					borderWidth: 0, backgroundColor: "transparent", borderColor: "transparent"
				}}
			>
				<div className="Down-Button">
					<text className="Next-Text">{this.props.nextText ? this.props.nextText : "Know More"}</text>
					<div className="Down-Icon">
						<FaAngleDoubleDown size={32}/>
					</div>
				</div>
			</button>
		);
	}

};