import React from "react";
import "./sections.css";
import {GoMail} from 'react-icons/go';
import {FaLinkedin, FaGithub} from "react-icons/fa"
import {Col, Container, Row} from "react-bootstrap";

export default class Touch extends React.Component {
	render() {
		return (

			<Container className="Multi-Section-Body">
				<Container fluid className="Sub-Section">
					<text className="Sub-Section-Title">SOCIAL</text>
					<div className="header-bar"/>
					<Row>
						<Col>
							<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/yashodhank"
							   className="Image-Link"><
								FaLinkedin size={42}/>
							</a>
						</Col>
						<Col>
							<a target="_blank" rel="noopener noreferrer" href="https://github.com/ykumthekar4929"
							   className="Image-Link">
								<FaGithub size={42}/>
							</a>
						</Col>
					</Row>
				</Container>

				<Container fluid className="Sub-Section">
					<text className="Sub-Section-Title">CONTACT ME</text>
					<div className="header-bar"/>

					<Row>
						<Col>
							<a target="_blank" rel="noopener noreferrer" href="mailto:yashodhan.kumthekar@gmail.com" className="Image-Link">
									<div><GoMail size={42}/> <h className="Email-Text">yashodhan.kumthekar@gmail.com</h></div>
							</a>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
}

