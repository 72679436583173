import React from 'react';
import './App.css';
import ReactFullpage from '@fullpage/react-fullpage';
import Intro from "./sections/Intro"
import Touch from "./sections/Touch"
import {Container} from "react-bootstrap";

export default class Content extends React.Component {
	render() {
		return (
			<Container fluid>
				<ReactFullpage
					scrollingSpeed={800}
					v2compatible
					fadingEffect
					render={({state, fullpageApi}) => {
						return (
							<ReactFullpage.Wrapper>
								<div className="section">
									<Intro fullpageApi={fullpageApi}/>
								</div>
								<div className="section">
									<Touch fullpageApi={fullpageApi}/>
								</div>
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</Container>
		);
	}
}

